import React, { useEffect, useState } from "react";
import { openNotificationWithIcon } from "../App";
import { Navigate, useNavigate } from "react-router-dom";
import { store } from "../redux/store";
import { setUser_Data } from "../redux/slice/userSlice";

export const getImagePositionStyle = (canvasRef, section) => {
  if (!canvasRef.current) return {};

  const canvasRect = canvasRef.current.getBoundingClientRect();
  // Calculate dynamic position based on canvas size
  const dynamicLeft = (canvasRect.width - section.width + 400) / 2;
  const dynamicTop = (canvasRect.height * section.placementY) / 1920; // Adjust for container's initial height (300px)
  const width = (canvasRect.width * section.width) / 1080;
  const height = (canvasRect.height * section.height) / 1920;
  return {
    position: "absolute",
    left: `${dynamicLeft}px`,
    top: `${dynamicTop}px`,
    width: `${width}px`,
    height: `${height}px`,
    borderRadius: `${section.radius}px`,
    zIndex: 10,
  };
};

export const emailregex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const passregex = /^(?=.*\d)[A-Za-z\d]{8,}$/;

export const isTokenExpired = () => {
  const expirationTime = localStorage.getItem("tokenExpiration");
  if (Date.now() > expirationTime) {
    localStorage.removeItem("token");
    localStorage.removeItem("tokenExpiration");
    store.dispatch(setUser_Data({}));
    return true;
  } else {
    return false;
  }
};

export const isTokenValid = () => {
  const token = localStorage.getItem("token");
  const expirationTime = localStorage.getItem("tokenExpiration");

  // Check if the token is not set
  if (!token) {
    return false;
  }

  // Check if the token has expired
  if (Date.now() > expirationTime) {
    openNotificationWithIcon(
      "error",
      "Alert",
      "Time's up! Please log in to continue."
    );

    localStorage.removeItem("token");
    localStorage.removeItem("tokenExpiration");
    store.dispatch(setUser_Data({}));
    <Navigate to={"/"} />;
    return false;
  }

  // If token exists and is not expired
  return true;
};

export const filterData = (data, data1) => {
  return data.filter((item, index) => !data1[index]);
};

export const handleError = (error) => {
  switch (error.code) {
    case "UserNotFoundException":
      console.error("User not found.");
      openNotificationWithIcon("error", "Error", "User not found.");
      break;
    case "InvalidParameterException":
      console.error("Invalid parameter provided.");
      openNotificationWithIcon("error", "Error", "Invalid parameter provided.");
      break;
    case "InvalidPasswordException":
      console.error("Password does not conform to policy.");
      openNotificationWithIcon(
        "error",
        "Error",
        "Password does not conform to policy."
      );
      break;
    case "InvalidLambdaResponseException":
      console.error("Invalid response from AWS Lambda trigger.");
      openNotificationWithIcon(
        "error",
        "Error",
        "Invalid response from AWS Lambda trigger."
      );
      break;
    case "NotAuthorizedException":
      console.error("User is not authorized.");
      openNotificationWithIcon("error", "Error", "User is not authorized.");
      break;
    case "TooManyRequestsException":
      console.error("Too many requests. Please try again later.");
      openNotificationWithIcon(
        "error",
        "Error",
        "Too many requests. Please try again later."
      );
      break;
    case "LimitExceededException":
      console.error("Limit exceeded.");
      openNotificationWithIcon("error", "Error", "Limit exceeded.");
      break;
    case "InternalErrorException":
      console.error("Internal server error.");
      openNotificationWithIcon("error", "Error", "Internal server error.");
      break;
    case "CodeMismatchException":
      console.error("Invalid confirmation code.");
      openNotificationWithIcon("error", "Error", "Invalid confirmation code.");
      break;
    case "ExpiredCodeException":
      console.error("Confirmation code has expired.");
      openNotificationWithIcon(
        "error",
        "Error",
        "Confirmation code has expired."
      );
      break;
    default:
      console.error("An unknown error occurred:", error.message);
      openNotificationWithIcon("error", "Error", error.message);
  }
};

export const handleLoginError = (error) => {
  switch (error.code) {
    case "UserNotFoundException":
      console.error("User does not exist.");
      openNotificationWithIcon("error", "Error", "User does not exist.");
      break;
    case "NotAuthorizedException":
      console.error("Incorrect username or password.");
      openNotificationWithIcon(
        "error",
        "Error",
        "Incorrect username or password."
      );
      break;
    case "UserNotConfirmedException":
      console.error("User is not confirmed.");
      openNotificationWithIcon("error", "Error", "User is not confirmed.");
      break;
    case "PasswordResetRequiredException":
      console.error("Password reset is required.");
      openNotificationWithIcon("error", "Error", "Password reset is required.");
      break;
    case "TooManyFailedAttemptsException":
      console.error("Too many failed login attempts.");
      openNotificationWithIcon(
        "error",
        "Error",
        "Too many failed login attempts."
      );
      break;
    case "InternalErrorException":
      console.error("Internal server error.");
      openNotificationWithIcon("error", "Error", "Internal server error.");
      break;
    default:
      console.error("An unknown error occurred:", error.message);
      openNotificationWithIcon("error", "Error", error.message);
  }
};

export const handleResendOTPError = (error) => {
  switch (error.code) {
    case "UserNotFoundException":
      console.error("User not found.");
      openNotificationWithIcon("error", "Error", "User not found.");
      break;
    case "InvalidParameterException":
      console.error("Invalid parameter provided.");
      openNotificationWithIcon("error", "Error", "Invalid parameter provided.");
      break;
    case "TooManyRequestsException":
      console.error("Too many requests. Please try again later.");
      openNotificationWithIcon(
        "error",
        "Error",
        "Too many requests. Please try again later."
      );
      break;
    case "InternalErrorException":
      console.error("Internal server error.");
      openNotificationWithIcon("error", "Error", "Internal server error.");
      break;
    default:
      console.error("An unknown error occurred:", error.message);
      openNotificationWithIcon("error", "Error", error.message);
  }
};

export const handleSignupAndConfirmUserError = (error) => {
  switch (error.code) {
    case "UsernameExistsException":
      console.error("User already exists.");
      openNotificationWithIcon("error", "Error", "User already exists.");
      return "User already exists.";

    case "InvalidParameterException":
      console.error("Invalid parameter provided.");
      openNotificationWithIcon("error", "Error", "Invalid parameter provided.");
      return "Invalid parameter provided.";

    case "InvalidPasswordException":
      console.error("Password does not conform to policy.");
      openNotificationWithIcon(
        "error",
        "Error",
        "Password does not conform to policy."
      );
      return "Password does not conform to policy.";

    case "CodeMismatchException":
      console.error("Invalid confirmation code.");
      openNotificationWithIcon("error", "Error", "Invalid confirmation code.");
      return "Invalid confirmation code.";

    case "ExpiredCodeException":
      console.error("Confirmation code has expired.");
      openNotificationWithIcon(
        "error",
        "Error",
        "Confirmation code has expired."
      );
      return "Confirmation code has expired.";

    default:
      console.error("An unknown error occurred:", error.message);
      openNotificationWithIcon("error", "Error", error.message);
      return "An unknown error occurred:", error.message;
  }
};

export const handleForgotPasswordAndConfirmCodeError = (error) => {
  switch (error.code) {
    case "UserNotFoundException":
      console.error("User not found.");
      openNotificationWithIcon("error", "Error", "User not found.");
      break;
    case "InvalidParameterException":
      console.error("Invalid parameter provided.");
      openNotificationWithIcon("error", "Error", "Invalid parameter provided.");
      break;
    case "InvalidPasswordException":
      console.error("Password does not conform to policy.");
      openNotificationWithIcon(
        "error",
        "Error",
        "Password does not conform to policy."
      );
      break;
    case "InvalidLambdaResponseException":
      console.error("Invalid response from AWS Lambda trigger.");
      openNotificationWithIcon(
        "error",
        "Error",
        "Invalid response from AWS Lambda trigger."
      );
      break;
    case "NotAuthorizedException":
      console.error("User is not authorized.");
      openNotificationWithIcon("error", "Error", "User is not authorized.");
      break;
    case "TooManyRequestsException":
      console.error("Too many requests. Please try again later.");
      openNotificationWithIcon(
        "error",
        "Error",
        "Too many requests. Please try again later."
      );
      break;
    case "LimitExceededException":
      console.error("Limit exceeded.");
      openNotificationWithIcon("error", "Error", "Limit exceeded.");
      break;
    case "InternalErrorException":
      console.error("Internal server error.");
      openNotificationWithIcon("error", "Error", "Internal server error.");
      break;
    case "CodeMismatchException":
      console.error("Invalid confirmation code.");
      openNotificationWithIcon("error", "Error", "Invalid confirmation code.");
      break;
    case "ExpiredCodeException":
      console.error("Confirmation code has expired.");
      openNotificationWithIcon(
        "error",
        "Error",
        "Confirmation code has expired."
      );
      break;
    default:
      console.error("An unknown error occurred:", error.message);
      openNotificationWithIcon("error", "Error", error.message);
  }
};

export const useResponsiveWidth = () => {
  const [width, setWidth] = useState(window.innerWidth / 4.3);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth / 4.3);
    };

    window.addEventListener("resize", handleResize);
    // Call handler immediately to set initial width
    handleResize();

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return width;
};
