import React from "react";
import HeaderComponent from "./HeaderComponent";
import FooterComponent from "./FooterComponent";
import { Outlet } from "react-router-dom";
import Headroom from "react-headroom";

const Layout = () => {
  return (
    <>
      {/* <Headroom
        style={{
          WebkitTransition: "all .5s ease-in-out",
          MozTransition: "all .5s ease-in-out",
          OTransition: "all .5s ease-in-out",
          transition: "all .5s ease-in-out",
          zIndex: 100,
        }}
      > */}
      <HeaderComponent />
      {/* </Headroom> */}
      <Outlet />
      <FooterComponent />
    </>
  );
};

export default Layout;
